<template>
  <div class="emoji-manage">
    <el-row class="header" type="flex">
      <el-col :span="18" class="search-option">
        <el-input
          @keyup.enter.native="handleSearch"
          v-model="searchKey"
          class="search-input"
          placeholder="输入表情包名称"
          :clearable="true"
        ></el-input>
        <el-button style="margin: 0 8px" type="primary" @click="handleSearch">搜索</el-button>
      </el-col>
    </el-row>

    <div class="content">
      <el-table :data="list" border row-key="id">
        <el-table-column label="表情id" prop="id"> </el-table-column>
        <el-table-column label="图片" prop="pic_url">
          <template slot-scope="scope">
            <img :src="scope.row.pic_url" width="80" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="pic_name"> </el-table-column>
        <el-table-column label="作者" prop="author"> </el-table-column>
        <el-table-column label="售价(星币)" prop="sale_price"> </el-table-column>
        <el-table-column label="销售总额(星币)" prop="total_sale_amount"></el-table-column>
        <el-table-column label="购买人数" prop="buy_num"> </el-table-column>
        <el-table-column label="分润比" prop="share_profit_percent"> </el-table-column>
        <el-table-column label="平台抽成(光辉)" prop="platform_profit_amount"></el-table-column>
        <el-table-column label="上架时间" prop="craeted_time">
          <template slot-scope="scope">
            {{ scope.row.craeted_time | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="emoticon_status">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.emoticon_status | formatStatus }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button
              size="mini"
              :type="scope.row.emoticon_status === 9 ? 'danger' : 'primary'"
              @click="handleStatus(scope.row)"
            >
              {{ scope.row.emoticon_status === 9 ? "下架" : "待上架" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getEmojiList, setEmojiStatus } from "@/api/star-admin.js";
export default {
  data() {
    return {
      searchKey: "",
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
    };
  },
  computed: {},
  watch: {},
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    formatStatus: function(value) {
      return value === 9 ? "售卖中" : "已下架";
    },
  },
  mounted() {
    this.getEmojiList();
  },
  // todo mock
  methods: {
    async getEmojiList() {
      const params = {
        page: this.currentPage,
        page_size: this.pageSize,
        key_words: this.searchKey,
      };
      try {
        const { data } = await getEmojiList(params);
        this.list = data.data.items;
        this.total = data.data.total;
      } catch (error) {
        console.log(error);
      }
    },
    handleStatus(row) {
      let nextStatus = row.emoticon_status === 9 ? -1 : 9;
      let nextText = row.emoticon_status === 9 ? "下架" : "上架";
      this.$confirm("确认执行该操作?", nextText, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        setEmojiStatus({ id: row.id, status: nextStatus }).then(() => {
          this.$message({
            message: "编辑成功",
            duration: 3000,
            type: "success",
          });
          this.getEmojiList();
        });
      });
    },
    closeDialog() {},
    handleSearch() {
      this.currentPage = 1;
      this.getEmojiList();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getEmojiList();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getEmojiList();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin: 20px;
}

.search-input {
  width: 300px;
}
.content {
  padding: 0 20px 20px;
}
</style>
